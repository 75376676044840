import { intervalToDuration, parseISO, format } from "date-fns";
import { parse } from "postgres-interval";

export const userTimezone = Intl.DateTimeFormat()
  .resolvedOptions()
  .timeZone.trim();

// console.log("user TZ", userTimezone);

export function formatDurationClock(fromDate, toDate) {
  const { days, hours, minutes, seconds } = intervalToDuration({
    start: fromDate,
    end: toDate
  });

  return String(hours + days * 24).padStart(2, 0) + ":" + String(minutes).padStart(2, 0) + ":" + String(seconds).padStart(2, 0);
}

export function minutesFromPostgresInterval(interval) {
  const parsed = parse(interval);
  return parsed.days * 60 * 24 + parsed.hours * 60 + parsed.minutes;
}

export function durationNameFromPostgresInterval(interval) {
  const minutes = minutesFromPostgresInterval(interval);
  if (minutes >= 180) {
    return "marathon";
  } else if (minutes > 90) {
    return "extended";
  } else {
    return "standard";
  }
}

export function formattedTime(objDate) {
  return objDate
    .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })
    .toLowerCase()
    .replace("am", "AM")
    .replace("pm", "PM");
}

export function formatTimeFromISO(dateString) {
  const date = parseISO(dateString);
  return format(date, "HH:mm");
}

export function bottomOfTheHour(objDate) {
  objDate.setMinutes(0, 0, 0);
  return objDate;
}

export function getTimeZoneAbbreviation(date, timeZone) {
  return new Intl.DateTimeFormat("en-US", { timeZone, timeZoneName: "short" }).formatToParts(date).find(part => part.type === "timeZoneName").value;
}
